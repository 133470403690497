/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { Controller } from '@hotwired/stimulus';
import CSVBoxImporter from '@csvbox/csvboxjs';
import { csrfToken } from '@rails/ujs';

// FIXME: controller is deprecated, replace with app/frontend/controllers/csvbox2_controller.js
export default class extends Controller {
  static values = {
    entity: String,
    userId: Number,
    userEmail: String,
    companyId: Number,
    licenseId: Number,
    appId: Number,
    departments: Array,
    locations: Array,
    sublocations: Array,
    legalEntities: Array,
    assignee: Array,
    apiKey: String,
    bulkUploadPath: String,
    bulkUploadCompletePath: String,
  };

  static targets = ['modalBtn'];

  ENTITIES = {
    location: { resourceType: 'Location' },
    sublocation: { resourceType: 'Sublocation' },
    department: { resourceType: 'Department' },
    legal_entity: { resourceType: 'LegalEntity' },
    device: { resourceType: 'Device' },
    user: { resourceType: 'User' },
    license_assignee: { resourceType: 'LicenseAssignee' },
    app_assignee: { resourceType: 'AppAssignee' },
    leased_asset: { resourceType: 'LeasedAsset' },
  };

  openModal() {
    this._beforeModalLoad();
    this.complete = false;
    const self = this;
    this.entityImporterPromise = new Promise((resolve) => {
      const entityImporter = new CSVBoxImporter(
        self.apiKeyValue,
        {},
        self._onImportComplete.bind(self),
      );
      entityImporter.listen('onReady', () => resolve(entityImporter));
      if (!entityImporter.id) entityImporter.setUpImporter();
    });

    this
      .entityImporterPromise
      .then(this._createBulkUpload.bind(this))
      .then(({ entityImporter, bulkUpload }) => {
        // NOTE: According to the CSVBox docs, this allows only 4 attributes besides the user_id
        entityImporter.setUser({
          user_id: self.userIdValue,
          company_id: self.companyIdValue,
          app_id: self.appIdValue,
          email: self.userEmailValue,
          token: bulkUpload.token,
        });

        self._setDeviceColumns(entityImporter);
        self._setUserColumns(entityImporter);

        self._afterModalLoad();
        entityImporter.openModal();
      })
      .catch((error) => {
        self._afterModalLoad();
        this.complete = true;
        console.error('csvbox controller: ', error);
        alert(error.message);
      });
  }

  disconnect() {
    if (!this.complete) return;

    this.entityImporterPromise?.then((i) => {
      i?.holder?.remove();
    });
  }

  _beforeModalLoad() {
    document.querySelector('#bulk-uploads-loader-overlay').style.display = 'block';
  }

  _afterModalLoad() {
    document.querySelector('#bulk-uploads-loader-overlay').style.display = 'none';
  }

  _onImportComplete(result) {
    if (this.complete) return;
    this.complete = true;
    return fetch(this.bulkUploadCompletePathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({
        result,
        entity: this.entityValue,
        user_id: this.userIdValue,
        company_id: this.companyIdValue,
        app_id: this.appIdValue,
        email: this.userEmailValue,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then(({ redirect_url }) => Turbo.visit(redirect_url))
      .catch((error) => console.error('csvbox controller: ', error));
  }

  async _createBulkUpload(entityImporter) {
    const { resourceType } = this.ENTITIES[this.entityValue];
    const { bulk_upload } = await fetch(this.bulkUploadPathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({ resource_type: resourceType, app_id: this.appIdValue }),
      credentials: 'same-origin',
    }).then((response) => response.json());
    if (!bulk_upload) throw new Error('Can not create a bulk upload');

    return { entityImporter, bulkUpload: bulk_upload };
  }

  _setDeviceColumns(entityImporter) {
    if (this.entityValue !== 'device') return;

    // TODO: make dynamic columns at server at json value to this controller
    entityImporter.setDynamicColumns([
      {
        column_name: 'assignee_id',
        display_label: 'Person',
        matching_keywords: 'person, user, email, assignee, Assignee\'s email address, user_id, users, assignee, user email',
        type: 'list',
        validators:
            {
              values: this.assigneeValue,
              case_sensitive: false,
            },
        required: false,
        position: 17,
      },
      {
        column_name: 'department_ids',
        display_label: 'Departments',
        matching_keywords: 'dep, departments, department_ids, department, department name, department names, department id, department ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.departmentsValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 24,
      },
      {
        column_name: 'location_ids',
        display_label: 'Locations',
        matching_keywords: 'loc, locations, location_ids, location, location name, location names, location id, location ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.locationsValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 25,
      },
      {
        column_name: 'sublocation_ids',
        display_label: 'Sublocations',
        matching_keywords: 'subloc, sublocations, sublocation_ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.sublocationsValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 26,
      },
      {
        column_name: 'legal_entity_ids',
        display_label: 'Legal Entities',
        matching_keywords: 'le, legal entities, legal entity_ids, legal entity, legal entity name, legal entity names, legal entity id, legal entity ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.legalEntitiesValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 27,
      },
    ]);
  }

  _setUserColumns(entityImporter) {
    if (this.entityValue !== 'user') return;

    entityImporter.setDynamicColumns([
      {
        column_name: 'department_ids',
        display_label: 'Departments',
        matching_keywords: 'dep, departments, department_ids, department, department name, department names, department id, department ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.departmentsValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 14,
      },
      {
        column_name: 'location_ids',
        display_label: 'Locations',
        matching_keywords: 'loc, locations, location_ids, location, location name, location names, location id, location ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.locationsValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 15,
      },
      {
        column_name: 'legal_entity_ids',
        display_label: 'Legal Entities',
        matching_keywords: 'le, legal entities, legal entity_ids, legal entity, legal entity name, legal entity names, legal entity id, legal entity ids',
        type: 'multiselect_list',
        validators:
            {
              values: this.legalEntitiesValue,
              case_sensitive: true,
              delimiter: '|',
            },
        required: false,
        position: 16,
      },
    ]);
  }
}
