/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { Controller } from '@hotwired/stimulus';
import CSVBoxImporter from '@csvbox/csvboxjs';
import { csrfToken } from '@rails/ujs';

const ENTITIES = {
  location: { resourceType: 'Location' },
  sublocation: { resourceType: 'Sublocation' },
  department: { resourceType: 'Department' },
  legal_entity: { resourceType: 'LegalEntity' },
  app_assignee: { resourceType: 'AppAssignee' },
  leased_asset: { resourceType: 'LeasedAsset' },
  user: {
    resourceType: 'User',
    dynamicColumns: function() {
      return [
        {
          column_name: 'department_ids',
          display_label: 'Departments',
          matching_keywords: 'dep, departments, department_ids, department, department name, department names, department id, department ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.departmentsValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 14,
        },
        {
          column_name: 'location_ids',
          display_label: 'Locations',
          matching_keywords: 'loc, locations, location_ids, location, location name, location names, location id, location ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.locationsValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 15,
        },
        {
          column_name: 'legal_entity_ids',
          display_label: 'Legal Entities',
          matching_keywords: 'le, legal entities, legal entity_ids, legal entity, legal entity name, legal entity names, legal entity id, legal entity ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.legalEntitiesValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 16,
        },
      ]
    }
  },
  device: {
    resourceType: 'Device',
    dynamicColumns: function() {
      return [
        {
          column_name: 'assignee_id',
          display_label: 'Person',
          matching_keywords: 'person, user, email, assignee, Assignee\'s email address, user_id, users, assignee, user email',
          type: 'list',
          validators:
              {
                values: this.assigneeValue,
                case_sensitive: false,
              },
          required: false,
          position: 17,
        },
        {
          column_name: 'department_ids',
          display_label: 'Departments',
          matching_keywords: 'dep, departments, department_ids, department, department name, department names, department id, department ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.departmentsValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 24,
        },
        {
          column_name: 'location_ids',
          display_label: 'Locations',
          matching_keywords: 'loc, locations, location_ids, location, location name, location names, location id, location ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.locationsValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 25,
        },
        {
          column_name: 'sublocation_ids',
          display_label: 'Sublocations',
          matching_keywords: 'subloc, sublocations, sublocation_ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.sublocationsValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 26,
        },
        {
          column_name: 'legal_entity_ids',
          display_label: 'Legal Entities',
          matching_keywords: 'le, legal entities, legal entity_ids, legal entity, legal entity name, legal entity names, legal entity id, legal entity ids',
          type: 'multiselect_list',
          validators:
              {
                values: this.legalEntitiesValue,
                case_sensitive: true,
                delimiter: '|',
              },
          required: false,
          position: 27,
        },
      ]
    }
  },
};

export default class extends Controller {
  static values = {
    entity: String,
    userId: Number,
    userEmail: String,
    companyId: Number,
    appId: Number,
    departments: Array,
    locations: Array,
    sublocations: Array,
    legalEntities: Array,
    assignee: Array,
    apiKey: String,
    bulkUploadPath: String,
    bulkUploadCompletePath: String,
    bulkUploadDiscardPath: String,
  };

  initialize() {
    this.completed = false;
    this.bulkUpload = null;
  }

  connect() {
    this.importer = new CSVBoxImporter(this.apiKeyValue, {}, this.onCallback.bind(this), { lazy: true });
    this.importer.listen("onReady", this.onReady.bind(this));
    this.importer.listen("onClose", this.onClose.bind(this));
    this.importer.listen("onSubmit", this.onSubmit.bind(this));
    this.importer.listen("onLoadStart", this.onLoadStart.bind(this));
  }

  openModal() {
    this.showLoader();
    this.importer.openModal();
  }

  // NOTE: when modal is closed
  async onClose(...args) {
    if (this.completed) return;

    await this.discardBulkUpload();
  }

  // NOTE: when modal start loading
  async onLoadStart(...args) {
    await this.createBulkUpload();
    this.setToken();
    this.setEntityColumns();
  }

  // NOTE: when modal is ready
  onReady() {
    this.hideLoader();
  }

  // NOTE: when submitted list is uploaded
  onCallback(result, _data) {
    this.completeSuccessfully(result);
  }

  setToken() {
    this.importer.setUser({
      user_id: this.userIdValue,
      company_id: this.companyIdValue,
      app_id: this.appIdValue,
      email: this.userEmailValue,
      token: this.bulkUpload.token
    })
  }

  setEntityColumns() {
    const { dynamicColumns } = this.entityOpts;
    if (!dynamicColumns) return;

    const columns =  dynamicColumns.bind(this)();
    this.importer.setDynamicColumns(columns);
  }

  get entityOpts() {
    return ENTITIES[this.entityValue];
  }

  async completeSuccessfully(result) {
    this.completed = true;
    try {
      const response = await fetch(this.bulkUploadCompletePathValue, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
        body: JSON.stringify({
          result,
          entity: this.entityValue,
          user_id: this.userIdValue,
          company_id: this.companyIdValue,
          app_id: this.appIdValue,
          email: this.userEmailValue,
        }),
        credentials: 'same-origin',
      })
      const { redirect_url } = await response.json();
      Turbo.visit(redirect_url);
    } catch (error) {
      console.error('csvbox controller: ', error)
    }
  }

  async createBulkUpload() {
    const response = await fetch(this.bulkUploadPathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      body: JSON.stringify({
        resource_type: this.entityOpts.resourceType,
        app_id: this.appIdValue
      }),
      credentials: 'same-origin',
    });

    const { bulk_upload: bulkUpload } = await response.json();
    if (!bulkUpload) throw new Error('Can not create a bulk upload');

    this.bulkUpload = bulkUpload;
  }

  async discardBulkUpload() {
    await fetch(this.bulkUploadDiscardPathValue, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken() },
      credentials: 'same-origin',
      body: JSON.stringify({
        bulk_upload_id: this.bulkUpload.id
      })
    });
    window.location.reload();
  }

  showLoader() {
    document.querySelector('#bulk-uploads-loader-overlay').style.display = 'block';
  }

  hideLoader() {
    document.querySelector('#bulk-uploads-loader-overlay').style.display = 'none';
  }

  // NOTE: unused importer callbacks, let's keep them for debugging
  // --------------------------------------------------------------

  // NOTE: when user submitted all date to modal
  onSubmit(...args) {
    // console.log('onSubmit', ...args)
  }
}
